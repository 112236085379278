import { DocumentPreviewDetailEntity } from "domain/entity/DocumentPreview/DocumentPreviewDetailEntity";
import { DocumentPreviewEntity } from "domain/entity/DocumentPreview/DocumentPreviewEntity";
import { DocumentPreviewSearchCriteria, EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA } from "domain/entity/DocumentPreview/DocumentPreviewSearchCriteria";
import { DocumentPreviewRepository } from "domain/repository/DocumentPreview/DocumentPreviewRepo";
import { EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA } from "presentation/constant/DocumentPreview/DocumentPreviewFirstPrintCriteria";
import { DocumentPreviewDetailModel } from "presentation/model/DocumentPreview/DocumentPreviewDetailModel";
import { DocumentPreviewModel } from "presentation/model/DocumentPreview/DocumentPreviewModel";
import { MenuItem } from "presentation/view/components/OverflowMenuButton";
import { Dispatch, SetStateAction } from "react";
import BaseViewModel from "../BaseViewModel";

interface DocumentPreviewDetailVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<DocumentPreviewDetailModel>> | ((value: SetStateAction<DocumentPreviewDetailModel>) => void),
    ],
    hdrDispatch: [
        Dispatch<SetStateAction<DocumentPreviewModel>> | ((value: SetStateAction<DocumentPreviewModel>) => void),
    ],
    documentPreviewRepo: DocumentPreviewRepository,
}

export const DocumentPreviewDetailVM = ({ dispatch, documentPreviewRepo, hdrDispatch }: DocumentPreviewDetailVMProps) => {
    const [documentPreviewDetailDispatch] = dispatch;
    const [documentPreviewDispatch] = hdrDispatch;

    const onCloseDtlScreen = () => {
        documentPreviewDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: false,
                isBackFromDetail: true,
                isAllowAutoSearch: true,
            }
        })
    }

    const onShowLoading = () => {
        documentPreviewDetailDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        documentPreviewDetailDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }
    const onPageInit = (currHdr: DocumentPreviewEntity) => {
        documentPreviewDetailDispatch(prevState => {
            return {
                ...prevState,
                currentDocumentPreview: currHdr
            }
        })
    }

    const searchInvItemDtl = async (searchCriteria: DocumentPreviewSearchCriteria) => {
        documentPreviewDetailDispatch(prevState => {
            return {
                ...prevState,
                documentPreviewDtlList: []
                //emptyPoolDatas:[],
                //selectedEmptyPoolDatas: []
            }
        })
        await documentPreviewRepo.searchInvoiceItemDtl({
            searchCriteria: searchCriteria,
            firstPrintCriteria: { ...EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA },
            docHdrList: [],
            docDtlList: [],
            invoiceItemDtlList: [],
            isDocNoBackDateFlag: 'N'
        }).then((data) => {
            documentPreviewDetailDispatch(prevState => {
                return {
                    ...prevState,
                    documentPreviewDtlList: data,
                    isShowMoveToPanel: false,
                    //emptyPoolDatas: data,
                    //selectedEmptyPoolDatas: []
                }
            })
        }).catch((error) => {
            return [];
        })
    }

    const handleMoveAfter = async (rows: DocumentPreviewDetailEntity[], targetItem: DocumentPreviewDetailEntity) => {
        let targetInvItems: any[] = [];

        documentPreviewDetailDispatch(prevState => {
            const allDatas = prevState.documentPreviewDtlList;

            const tempInvItems = allDatas.filter(itemData => !rows.some(row => itemData.itemDtlPk === row.itemDtlPk));

            let targetIndex: number = -1;
            for (let i = 0; i < tempInvItems.length; i++) {
                const tempRow = tempInvItems[i];

                if (((targetItem.tariffType ? targetItem.tariffType : "null") + (targetItem.tariffCode ? targetItem.tariffCode : "null") + (targetItem.prioritySequence ? targetItem.prioritySequence : "null"))
                    ===
                    ((tempRow.tariffType ? tempRow.tariffType : "null") + (tempRow.tariffCode ? tempRow.tariffCode : "null") + (tempRow.prioritySequence ? tempRow.prioritySequence : "null"))) {
                    targetIndex = i;
                }
            }

            targetInvItems = [...tempInvItems.slice(0, targetIndex + 1), ...rows, ...tempInvItems.slice(targetIndex + 1, tempInvItems.length)];
            return {
                ...prevState,
                documentPreviewDtlList: [],
            }
        });

        await documentPreviewRepo.apply({
            searchCriteria: { ...EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA },
            firstPrintCriteria: { ...EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA },
            docHdrList: [],
            docDtlList: [],
            invoiceItemDtlList: targetInvItems,
            isDocNoBackDateFlag: 'N'
        }).then((data) => {
            documentPreviewDetailDispatch(prevState => {
                let tmmId = prevState.currentDocumentPreview.id as number;
                let newSearchCriteria = { ...EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA }
                newSearchCriteria = {
                    ...newSearchCriteria,
                    docHdrId: tmmId,
                };

                searchInvItemDtl(newSearchCriteria);
                return {
                    ...prevState,
                    allFormState: { "applySuccess": "apply successful." }
                }
            })
        }).catch((error) => {
            documentPreviewDetailDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: { "applyFail": "apply failed." }
                }
            })
        })
    }

    const updateSelectedDtlRows = async (rows: any[]) => {
        documentPreviewDetailDispatch(prevState => {
            const moveAfterList = prevState.documentPreviewDtlList?.filter(item => !rows.some(dtl => dtl.itemDtlPk === item.itemDtlPk));
            const subMenuItemArray: MenuItem[] = [];

            moveAfterList?.forEach(item => {
                const labelName = /*item.prioritySequence + ". " +*/ item.tariffType + "/" + item.tariffCode;

                const isExisted = subMenuItemArray.some(subItem => subItem.label === labelName);
                if (!isExisted) {
                    subMenuItemArray.push({
                        label: labelName,
                        command: () => {
                            handleMoveAfter(rows, item);
                        }
                    });
                }
            });
            return {
                ...prevState,
                isShowMoveToPanel: false,
                selectedDtlRows: rows,
                subMenuItemArray: subMenuItemArray
            }
        })
    }

    const updateSelectedMoveRows = async (rows: any[]) => {
        documentPreviewDetailDispatch(prevState => {

            return {
                ...prevState,
                selectMoveToItem: rows,
            }
        })
    }

    const searchDataForMove = async (selectedInvtemDtlDatas: DocumentPreviewDetailEntity[]) => {
        documentPreviewDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowMoveToPanel: true,
            }
        })
        await documentPreviewRepo.searchDataForMove({
            searchCriteria: { ...EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA },
            firstPrintCriteria: { ...EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA },
            docHdrList: [],
            docDtlList: [],
            invoiceItemDtlList: selectedInvtemDtlDatas,
            isDocNoBackDateFlag: 'N'
        }).then((data) => {
            documentPreviewDetailDispatch(prevState => {
                return {
                    ...prevState,
                    moveToItemList: data,
                }
            })

        }).catch((error) => {
            documentPreviewDetailDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: { "savePoNoFail": "save P.O. No. error." }
                }
            })
        })
    }

    const onCancelMoveTo = () => {
        documentPreviewDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowMoveToPanel: false,
            }
        })
    }

    const addOrApplyDocDataForMove = async (selectedDocHdrDatas: DocumentPreviewEntity[], selectedDocDtlDatas: DocumentPreviewDetailEntity[]) => {
        let bool = await documentPreviewRepo.addOrApplyDocDataForMove({
            searchCriteria: { ...EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA },
            firstPrintCriteria: { ...EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA },
            docHdrList: selectedDocHdrDatas,
            docDtlList: [],
            invoiceItemDtlList: selectedDocDtlDatas,
            isDocNoBackDateFlag: 'N'
        }).then((data) => {
            documentPreviewDetailDispatch(prevState => {
                return {
                    ...prevState,
                    isShowMoveToPanel: false,
                    selectedDtlRows: []
                }
            })
            return data;

        }).catch((error) => {
            documentPreviewDetailDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: { "MoveFail": "Move Item error." }
                }
            })
            return false;
        })
        return bool;
    }
    return {
        onCloseDtlScreen: onCloseDtlScreen,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onPageInit: onPageInit,
        searchInvItemDtl: searchInvItemDtl,
        updateSelectedDtlRows: updateSelectedDtlRows,
        searchDataForMove: searchDataForMove,
        onCancelMoveTo: onCancelMoveTo,
        addOrApplyDocDataForMove: addOrApplyDocDataForMove,
        updateSelectedMoveRows: updateSelectedMoveRows,
    }
}