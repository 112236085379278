import { CustomCellRendererProps } from "ag-grid-react";
import { DECIMAL_2 } from "presentation/utils/numberUtil";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { DocumentPreviewConstant } from "./DocumentPreviewConstant";

const DOCUMENT_PREVIEW_CONSTANT = DocumentPreviewConstant.DetailTable;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
let numberFieldList: string[] = [];
export const INITIAL_DOCUMENT_PREVIEW_DETAIL_COL_DEF: any[] = [
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.TARIFF_TYPE,
        field: 'tariffType',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.TARIFF_CODE,
        field: 'tariffCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.TARIFF_CODE_DESC,
        field: 'tariffCodeDescTemp',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.DTL_DESC1,
        field: 'dtlDesc1',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.CHARGE_TYPE,
        field: 'chargeType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.SUB_CHARGE_TYPE,
        field: 'subChargeType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.COMMON_AC_PERCENTAGE,
        field: 'commonAcPercentage',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.CHARGE_AMOUNT,
        field: 'amountTemp',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 270,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.CHARGING_QTY,
        field: 'chargingQtyTemp',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        //dataType:"Double"
        //dataType: "date"
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.UNIT_PRICE,
        field: 'unitPrice',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 280,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.CHARGING_UOM,
        field: 'chargeQtyUom',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 160,
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.TAX,
        field: 'vatPercentage',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 120,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.TAX_AMOUNT,
        field: 'adjAmount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 300,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.ORIG_INVOICE_NUMBER,
        field: 'origInvoiceNumberTemp',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 230,
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.ORIG_INVOICE_ISSUSE_DATE,
        field: 'origInvoiceIssueDateTemp',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
        //dataType: "string"
    },

].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers, numberFieldList);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions, numberFieldList);
}
