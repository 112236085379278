import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { DocumentPreviewDetailEntity } from "domain/entity/DocumentPreview/DocumentPreviewDetailEntity";
import { DocumentPreviewEntity } from "domain/entity/DocumentPreview/DocumentPreviewEntity";
import { DocumentPreviewRequestEntity } from "domain/entity/DocumentPreview/DocumentPreviewRequestEntity";
import { DocumentPreviewFirstPrintCriteria } from "presentation/constant/DocumentPreview/DocumentPreviewFirstPrintCriteria";
import { axiosPostData } from "../axios/AxiosBasicImpl";
import documentAxiosInstance from "../axios/documentAxiosInstance";
import { DocumentPreviewRepository } from "./DocumentPreviewRepo";

export const DocumentPreviewRepoImpl = (): DocumentPreviewRepository => {
    const url = "/v1/documentPreview";

    const searchDocPreviewData = async (requestEntity: DocumentPreviewRequestEntity): Promise<DocumentPreviewEntity[]> => {
        const newUrl = url + "/search";

        return axiosPostData(documentAxiosInstance, `${newUrl}`, requestEntity).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const searchInvoiceItemDtl = async (requestEntity: DocumentPreviewRequestEntity): Promise<DocumentPreviewDetailEntity[]> => {
        const newUrl = url + "/searchInvItemDtl";

        return axiosPostData(documentAxiosInstance, `${newUrl}`, requestEntity).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const finalize = async (requestEntity: DocumentPreviewRequestEntity): Promise<ResponseEntity> => {
        const newUrl = url + "/finalize";

        try {
            return await axiosPostData(documentAxiosInstance, `${newUrl}`, requestEntity) as ResponseEntity;
        } catch (err) {
            throw err;
        }
    }

    const savePoNo = async (requestEntity: DocumentPreviewRequestEntity): Promise<Object> => {
        const newUrl = url + "/savePoNo";

        return axiosPostData(documentAxiosInstance, `${newUrl}`, requestEntity).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const reject = async (requestEntity: DocumentPreviewRequestEntity): Promise<Object> => {
        const newUrl = url + "/reject";

        return axiosPostData(documentAxiosInstance, `${newUrl}`, requestEntity).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const apply = async (requestEntity: DocumentPreviewRequestEntity): Promise<Object> => {
        const newUrl = url + "/apply";

        return axiosPostData(documentAxiosInstance, `${newUrl}`, requestEntity).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const searchDataForMove = async (requestEntity: DocumentPreviewRequestEntity): Promise<DocumentPreviewEntity[]> => {
        const newUrl = url + "/searchDataForMove";

        return axiosPostData(documentAxiosInstance, `${newUrl}`, requestEntity).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const addOrApplyDocDataForMove = async (requestEntity: DocumentPreviewRequestEntity): Promise<Boolean> => {
        const newUrl = url + "/addOrApplyDocDataForMove";

        return axiosPostData(documentAxiosInstance, `${newUrl}`, requestEntity).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const previewDocReport = async (requestEntity: DocumentPreviewRequestEntity): Promise<any> => {
        const newUrl = url + "/previewDocReport";

        return axiosPostData(documentAxiosInstance, `${newUrl}`, requestEntity).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const previewSupportingReport = async (requestEntity: DocumentPreviewRequestEntity): Promise<any> => {
        const newUrl = url + "/previewSupportingReport";

        return axiosPostData(documentAxiosInstance, `${newUrl}`, requestEntity).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const printAndSent = async (requestEntity: DocumentPreviewFirstPrintCriteria): Promise<ResponseEntity> => {
        const newUrl = url + "/printAndSent";

        try {
            return await axiosPostData(documentAxiosInstance, `${newUrl}`, requestEntity) as ResponseEntity;
        } catch (err) {
            throw err;
        }
    }

    return {
        searchDocPreviewData: searchDocPreviewData,
        searchInvoiceItemDtl: searchInvoiceItemDtl,
        finalize: finalize,
        savePoNo: savePoNo,
        reject: reject,
        apply: apply,
        searchDataForMove: searchDataForMove,
        addOrApplyDocDataForMove: addOrApplyDocDataForMove,
        previewDocReport: previewDocReport,
        previewSupportingReport: previewSupportingReport,
        printAndSent: printAndSent,
    }
}