export interface DocumentPreviewFirstPrintCriteria {
    docHdrIds: number[],
    finalizedBy: string,
    invPrinter: string,
    defaultInvPrinter: string,
    invFileLoc: string,
    suppPrinter: string,
    defaultSuppPrinter: string,
    suppFileLoc: string,

    [key: string]: string[] | string | boolean | number | number[] | null | undefined
}

export const EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA: DocumentPreviewFirstPrintCriteria = {
    invPrinter: "",
    defaultInvPrinter: "",
    invFileLoc: "",
    suppPrinter: "",
    defaultSuppPrinter: "",
    suppFileLoc: "",
    finalizedBy: "",
    docHdrIds: []
}